<template>
    <div>
        <v-snackbar
            v-if="isLoading"
            v-model="isLoading"
            :timeout="5000"
            :top="true"
            color="info"
        >Loading...
        </v-snackbar>

        <v-snackbar
            v-if="error"
            v-model="error"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-alert</v-icon>
            {{ error }}
        </v-snackbar>
        <v-snackbar
            v-if="deleteError"
            v-model="deleteError"
            :timeout="5000"
            :top="true"
            color="error"
        >
            <v-icon>mdi-delete</v-icon>
            {{ deleteError }}
        </v-snackbar>

        <v-dialog v-if="item" v-model="item" content-class="zoomImageDialog" fullscreen hide-overlay
                  transition="fade-transition">
            <v-card>
                <!--<div style="width: 100%; height: 100%;">-->
                <Zoomcomparify :item="item" :colorized="colorized" :on-viewer-click="toggleGui">
                </Zoomcomparify>
                <div class="retrosnapShowInfo"
                                v-if="item"
                     :style="hideGui ? 'pointer-events: none !important;' : ''">
                    <v-card>
                        <v-card-title
                            class="text-h5 photographTitle"
                            primary-title
                        >
                            {{ $t('app.Retrosnap.CreatedBy', { author: item.author.name }) }}
                        </v-card-title>

                        <v-card-text ref="commentsContainer">
                            <v-card-subtitle v-if="item.text">{{item.text}}</v-card-subtitle>
                            <div v-if="item.dateCreated">{{ $t('app.Retrosnap.Created') }}: {{ $d(Date.parse(item['dateCreated']),'long') }}</div>
                            <div v-if="item.place && item.place.name">{{ $t('app.Photograph.Place') }}: {{item.place.name}}</div>
                            <a href="#" v-if="item" v-on:click.stop.prevent="showCollectionInfoDialog=true" x-small  v-html="attribution"></a>

                        </v-card-text>
                    </v-card>
                    <v-card-actions class="" style="z-index:7; pointer-events:all; position:fixed; bottom:40px; right:0px; flex-direction: column-reverse;">


                        <v-speed-dial
                            v-if="item"
                            style="margin-bottom: 20px"
                            v-model="speedDialShow"
                            direction="top"
                            transition="slide-y-reverse-transition"
                        >
                            <template v-slot:activator>
                                <v-btn
                                    v-model="speedDialShow"
                                    icon large
                                >
                                    <v-icon v-if="speedDialShow">
                                        mdi-close
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-dots-horizontal
                                    </v-icon>
                                </v-btn>
                            </template>


                            <v-tooltip v-if="item.photograph.isBlackAndWhite===true" right>
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn @click.stop="colorized=!colorized" v-on="onTooltip" icon>
                                        <v-icon v-if="colorized">mdi-invert-colors-off</v-icon>
                                        <v-icon v-if="!colorized">mdi-invert-colors</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('app.Photograph.Colorized') }}</span>
                            </v-tooltip>

                            <v-tooltip top>
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn v-on="onTooltip" v-if="item.place && item.place.geo"
                                           :to="{name: 'MapShowGeo', params: { geo: item.place.geo.latitude+','+item.place.geo.longitude }}"
                                           icon>
                                        <v-icon>icon-Marker</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('app.Photograph.ShowOnMap') }}</span>
                            </v-tooltip>
                            <v-tooltip top >
                                <template v-slot:activator="{ on: onTooltip }">
                                    <v-btn v-if="isAuthor" v-on="onTooltip" :to="{name: 'RetrosnapUpdate', params: {id: item['@id']}}" icon>
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('app.Retrosnap.Edit') }}</span>
                            </v-tooltip>

                        </v-speed-dial>


                        <v-tooltip left v-if="!speedDialShow" style="transition-delay: 0.15s;">
                            <template v-slot:activator="{ on: onTooltip }">
                                <v-btn @click.native.stop.prevent="showGallerySelectDialog(item)" icon class="mb-2" v-on="{...onTooltip}" ><v-icon>mdi-notebook-plus</v-icon></v-btn>
                            </template>
                            <span>{{ $t('app.Gallery.Add') }}...</span>
                        </v-tooltip>

                        <v-tooltip top v-if="!speedDialShow">
                            <template v-slot:activator="{ on: onTooltip }">
                                <v-btn v-on="onTooltip" :to="{name: 'PhotographShow'+ (colorized ? 'Color' : ''), params: {id: item['photograph']['@id']}}" icon>
                                    <v-icon>mdi-image-search-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('app.Retrosnap.PhotographShow') }}</span>
                        </v-tooltip>
                        <v-tooltip top v-if="!speedDialShow">
                            <template v-slot:activator="{ on: onTooltip }">
                                <v-btn @click.stop="addComment" v-on="onTooltip" icon>
                                    <v-icon>mdi-comment-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('app.Photograph.AddComment') }}</span>
                        </v-tooltip>
                        <ShareButton v-if="!speedDialShow"
                                     :url="metaUrl"
                                     :title="metaTitle"
                                     :description="metaDescription"
                                     quote="Schau dir dieses RetroSnap auf Nimmerso an"
                                     :hashtags="'nimmerso,'+metaHashtags.join(',')"
                                     :media="metaImage"
                                     twitterUser="nimmerso"
                        ></ShareButton>
                    </v-card-actions>
                    <v-card-actions v-if="item" >
                        <v-row>
                            <v-col xs="10" sm="10" md="8" lg="6" xl="4">
                                <v-btn
                                    rounded
                                    outlined
                                    block
                                    style="margin-bottom:7px; pointer-events: stroke;"
                                    @click="addComment()"


                                >
                                    <v-row>
                                        <v-col class="text-capitalize text-left">{{$t('app.Comment.Comment')}}...</v-col>
                                        <v-col class="text-right">
                                            <v-icon>
                                                mdi-comment-plus
                                            </v-icon>
                                        </v-col>
                                    </v-row>


                                </v-btn>
                            </v-col>
                            <v-col cols="2" xs="2" sm="2" md="4" lg="6" xl="8">
                                <v-tooltip top style="transition-delay: 0.15s;" v-if="item.comments.length>0">
                                    <template v-if="item.comments.length>0" v-slot:activator="{ on: onTooltip }">
                                        <v-badge

                                            :content="item.comments.length"
                                            :value="item.comments.length"
                                            offset-x="16"
                                            offset-y="16"
                                            overlap
                                        >
                                            <v-btn @click.stop="showComments=true" v-on="onTooltip" icon style="pointer-events: stroke;" >
                                                <v-icon>icon-SpeechBubble</v-icon>
                                            </v-btn>
                                        </v-badge>


                                    </template>
                                    <span>{{ $t('app.Photograph.ShowComments') }}</span>
                                </v-tooltip>
                                <v-tooltip v-else top style="transition-delay: 0.15s;" >
                                    <template v-slot:activator="{ on: onTooltip }">
                                        <v-btn  @click.stop="showComments=true" v-on="onTooltip" icon style="pointer-events: stroke;" >
                                            <v-icon>icon-SpeechBubble</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('app.Photograph.ShowComments') }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>

                    </v-card-actions>
                </div>
                <!--</div>-->
                <v-tooltip left v-if="item">
                    <template v-slot:activator="{ on: onTooltip }">
                        <v-btn @click.stop="closeWin" icon
                               class="closeWinButton"
                               right
                               fixed
                               v-on="onTooltip">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('app.Close') }}</span>
                </v-tooltip>

            </v-card>
        </v-dialog>

        <v-bottom-sheet content-class="retrosnapShowComments" inset hide-overlay
                        v-model="showComments"
                        v-if="showComments"
                        scrollable
                        class="noBackground"
        >
            <v-card v-if="item" class="bgBlur">
                <v-btn top right absolute icon class="pa-0" @click="showComments = false" style="z-index:1">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-title >{{commentItems.length}} {{ $t('app.Comments') }}</v-card-title>
                <v-card-text class="py-4" v-if="commentItems.length==0">Keine Kommentare vorhanden</v-card-text>

                <v-card-text ref="commentsContainer">

                    <comment-item
                        v-for="commentItem in commentItems"
                        hover
                        elevation="2"
                        class="my-2 py-1"
                        :color="(activeComment===commentItem.id ? 'grey lighten-4' : 'grey lighten-5')"
                        :id="'comment_'+commentItem.id"
                        :ref="'comment_'+commentItem.id"
                        :comment-item="commentItem"
                        :comment-click="commentClick"
                        :active-comment="activeComment"
                        :edit-comment="editComment"
                        :delete-comment="deleteComment"
                        :upvote-comment="upvoteComment"
                        :downvote-comment="downvoteComment"
                        :report-inappropriate-comment="reportInappropriateComment"
                        :key="commentItem.id">

                    </comment-item>

                </v-card-text>
                <v-card-actions v-if="item" >
                    <v-btn
                        rounded
                        outlined
                        block
                        style="margin-bottom:7px; pointer-events: stroke;"
                        @click="addComment()"
                    >
                        <v-row>
                            <v-col class="text-capitalize text-left">{{$t('app.Comment.Comment')}}...</v-col>
                            <v-col class="text-right">
                                <v-icon>
                                    mdi-comment-plus
                                </v-icon>
                            </v-col>
                        </v-row>


                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
            inset
            hide-overlay
            scrollable
            v-if="showCommentDialog" v-model="showCommentDialog"
            @click:outside="commentDialogClose"
        >
            <comment-dialog
                :do-comment-dialog-close="doCommentDialogClose"
                :new-comment="newComment"
                :on-send-comment-form="onSendCommentForm"
                :update-comment-field="updateCommentField"
                :submit-comment="submitComment"
            >
            </comment-dialog>
        </v-bottom-sheet>
        <v-dialog
            v-model="showCollectionInfoDialog"
            v-if="item"
        >
            <v-card>
                <v-card-title class="text-h5">
                    {{ item.photograph.collection.headline }}
                    <v-spacer></v-spacer>
                    <v-btn top right absolute icon @click="showCollectionInfoDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-subtitle v-if="item.photograph.collection.about">{{ item.photograph.collection.about }}</v-card-subtitle>
                <v-card-text v-if="item.photograph.collection.text" v-html="item.photograph.collection.text"></v-card-text>
                <v-card-text><v-btn small @click.stop.prevent="showPhotosFromCollection">{{item.photograph.collection.photographsCount}} RetroPics</v-btn></v-card-text>

                <v-card-text v-if="this.item.photograph.collection.collectionCopyrightUrl"><a :href="this.item.photograph.collection.collectionCopyrightUrl" target="_blank">{{this.item.photograph.collection.collectionCopyrightUrl}}</a></v-card-text>
                <v-card-text v-if="this.item.photograph.collection.licenseName">
                    <a v-if="this.item.photograph.collection.licenseUrl" rel="license" :href="this.item.photograph.collection.licenseUrl" target="_blank">{{this.item.photograph.collection.licenseName}}</a>
                    <span v-else>{{this.item.photograph.collection.licenseName}}</span>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="gallerySelectDialog" max-width="400">
            <v-card>
                <v-card-title>{{ $t('app.Gallery.Add')}}</v-card-title>
                <v-card-text>
                    <v-autocomplete
                        value=""
                        :label="$t('app.Gallery.Select')"
                        :items="imagegalleries"
                        item-value="@id"
                        item-text="headline"
                        v-model="selectedGallery"
                        dense
                        filled
                        :placeholder="$t('app.Gallery.Select')"
                    >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-avatar
                                    tile
                                    size="64"
                                >
                                    <v-img
                                        :src="entrypoint+'/image_galleries/'+data.item['thumbnail']+'/icon'"

                                    ></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.headline"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>

                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        :to="{ name: 'ImageGalleryCreate' }"
                        color="secondary"
                        plain
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('app.Gallery.Create') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        plain
                        text
                        @click="selectGalery"
                    >
                        {{$t('app.Gallery.AddShort')}}
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-dialog
            v-if="showCommentLoginDialog"
            v-model="showCommentLoginDialog"
            transition="dialog-bottom-transition"
            max-width="600"
        >
            <v-card class="text-center">
                <v-toolbar
                >{{ $t('app.Comment.PleaseLogIn') }}</v-toolbar>
                <v-card-title class="text-h5">

                    <v-spacer></v-spacer>
                    <v-btn top right absolute icon @click="showCommentLoginDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>{{ $t('app.Comment.LoginText') }}<br/>
                    {{ $t('app.Comment.LoginText2') }}</v-card-text>
                <v-card-actions><v-spacer></v-spacer><v-btn @click="goToStart">{{ $t('app.Comment.LoginRegister') }}</v-btn><v-spacer></v-spacer></v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style>
    .closeWinButton {
        z-index: 10001;
        top: max( 30px, env( safe-area-inset-top, 30px ) );
    }

    .homevcardactions i {
        text-shadow: 0 1px 2px black;
    }

    .comparify-image {
        width: 100%;
        height: 100%;
        object-fit: contain;


    }
    .retrosnapShowInfo{
        box-shadow: none;
        max-height: 340px;
        pointer-events: none;
        position:fixed;
        right:0;
        left:0;
        background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.3) 40%) !important;
        bottom:0;

    }
    .retrosnapShowInfo .btn, .retrosnapShowInfo a{
        pointer-events: auto;
    }
    .retrosnapShowInfo  .v-card__subtitle{
        padding: 0;
    }

    .retrosnapShowInfo >.v-card{
        background-color: initial !important;
        max-height: 240px;
        overflow-y:auto;
        mask-image: linear-gradient(transparent , black 5%, black 95%,transparent);
        mask-mode: alpha;
        padding-top:20px;
        padding-right:25px;
        pointer-events: stroke;

    }
</style>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import { ENTRYPOINT } from '../../config/entrypoint'
    import Zoomcomparify from './Zoomcomparify.vue'
    import CommentItem from '../comment/CommentItem'
    import CommentDialog from '../comment/CommentDialog'
    import fetch from '../../utils/fetch'
    import ShareButton from '@/components/share/ShareButton'
    export default {
        components: {
            Zoomcomparify, CommentDialog, CommentItem, ShareButton
        },
        computed: {
            ...mapGetters ({
                deleteError: 'retrosnap/del/error',
                error: 'retrosnap/show/error',
                isLoading: 'retrosnap/show/isLoading',
                item: 'retrosnap/show/retrieved',
                settings: 'settings/items',
                token_data: 'user/token/token_data',
                personItem: 'person/show/retrieved',
                commentDeletedItem: 'comment/del/deleted',
                commentError: 'comment/list/error',
                commentItems: 'comment/list/items',
                commentIsLoading: 'comment/list/isLoading',
                commentView: 'comment/list/view',
                commentCreated: 'comment/create/created',
                commentRetrieved: 'comment/update/retrieved',
                commentUpdated: 'comment/update/updated',
            }),
            metaDescription() {
                return this.$t('app.MetaDescription');
            },
            attribution() {
                let attribution=this.item.photograph.collection.headline;
                if(this.item.photograph.collection.licenseName) {
                    attribution += ', ';
                    attribution += this.item.photograph.collection.licenseUrl ? '<a href="' + this.item.photograph.collection.licenseUrl + '" rel="license noreferrer" target="_blank">' + this.item.photograph.collection.licenseName + '</a>' : this.item.photograph.collection.licenseName;
                }
                //attribution+= ' | '+'&copy; <a href="https://nimmerso.com/">nimmerso.com</a>';
                return attribution;
            }
        },
        watch: {
            personItem: function () {
                this.imagegalleries = this.personItem.imageGalleries
            },
            $route () {
                this.init()
            },
            showCommentDialog: function () {
                if (this.showCommentDialog === false && this.pathEditMode === false) this.commentDialogClose()
            },
            commentCreated: function () {
                this.getComments('/comments?retrosnap=' + this.$route.params.id)
            },
            commentItems: function () {
                this.filterCommentItems()
                if (typeof this.$route.params.comment_id !== 'undefined') {
                    this.activeComment = decodeURIComponent(this.$route.params.comment_id)
                    if (this.activeComment == false) this.activeComment = null
                    this.showInfo = true
                    let commentItem = this.commentItems.filter((cI) => cI.id === this.activeComment)[0]
                    this.showBackside = commentItem.isBackside === true
                    this.$nextTick(() => {
                        let top = 0
                        if (this.activeComment && this.$refs['comment_' + this.activeComment]) {
                            let vele = this.$refs['comment_' + this.activeComment][0]
                            top = vele.$el.offsetTop - 100
                            this.$refs['commentsContainer'].scrollTo(0, top)
                        }
                    })
                }

            },
            item: function () {
                if (this.item === [] || this.item === null) return
                this.metaItem = this.item
                this.metaTitle = 'RetroSnap by '+this.metaItem.author.name+' - Nimmerso'
                this.metaDescription = this.htmlAsText((this.metaItem.text!==null ? this.metaItem.text+' ' : '' ) +'© '+ this.attribution)
                this.metaImage = this.entrypoint + '/thumbnail/display' + this.metaItem.filePath
                this.metaUrl = this.getCompleteUrl()
                this.metaHashtags = []
                const size=this.metaItem.photograph.size;
                if(typeof size[0] !== 'undefined' && typeof size[1] !== 'undefined'){
                    if (size[0]>size[1]){
                        this.metaImageWidth=1024;
                        this.metaImageHeight=Math.round(size[1]/(size[0]/1024));
                    }else{
                        this.metaImageHeight=1024;
                        this.metaImageWidth=Math.round(size[0]/(size[1]/1024));
                    }
                }



                /*if (this.categories) {
                    this.metaHashtags = this.categories.filter((category) => this.metaItem.categories.indexOf(category['@id']) !== -1).map(category => category.name)
                }*/
                if (this.item.photograph.isBlackAndWhite === false) {
                    this.colorized = false;
                }
            },
            hideGui: function(){
                let commentOverlays = document.getElementsByClassName('commentOverlay');
                commentOverlays.forEach((commentOverlay) => {
                    commentOverlay.style.opacity=this.hideGui ? '0': '1';
                    commentOverlay.style.transition=this.hideGui ? 'opacity 500ms': 'opacity 500ms';
                })

                let retrosnapShowInfoOverlay = document.querySelector('.retrosnapShowInfo');
                retrosnapShowInfoOverlay.style.opacity=this.hideGui ? '0': '1';
                retrosnapShowInfoOverlay.style.transition='opacity 500ms';
                let closeWinButton = document.querySelector('.closeWinButton');
                closeWinButton.style.opacity=this.hideGui ? '0': '1';
                closeWinButton.style.transition='opacity 500ms';
            },
        },
        data: () => ({
            entrypoint: ENTRYPOINT,
            metaItem: {},
            metaTitle: 'Nimmerso - am selben Ort, zu andrer Zeit',
            metaUrl: null,
            metaImage: null,
            metaImageWidth: null,
            metaImageHeight: null,
            metaHashtags: [],
            zoomFactor: 100,
            colorized: false,
            showBackside: false,
            showInfo: false,
            showCommentDialog: false,
            showComments: false,
            newComment: {box: null, isPrivate: false},
            pathEditMode: false,
            activeComment: null,
            filteredCommentItems: [],
            showCollectionInfoDialog: false,
            hideGui: false,
            hideGuiTimeout: null,
            speedDialShow: false,
            selectedGallery: null,
            gallerySelectDialog: false,
            selectedSlide: null,
            imagegalleries: [],
            showCommentLoginDialog: false,
        }),
        beforeDestroy () {
            this.reset()
        },
        metaInfo () {
            return {
                title: this.metaTitle,
                meta: [
                    {name: 'description', content: this.metaDescription},

                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: this.metaTitle},
                    {name: 'twitter:description', content: this.metaDescription},
                    // image must be an absolute path
                    {name: 'twitter:image', content: this.metaImage},

                    {property: 'og:title', content: this.metaTitle},
                    {property: 'og:site_name', content: 'Nimmerso'},
                    {property: 'og:description', content: this.metaDescription},
                    {property: 'og:type', content: 'website'},
                    {property: 'og:url', content: this.metaUrl},
                    {property: 'og:image', content: this.metaImage},
                    {property: 'og:image:width', content: this.metaImageWidth},
                    {property: 'og:image:height', content: this.metaImageHeight}
                ]
            }
        },

        created () {
            this.init()
        },

        methods: {
            ...mapMutations({
                setError: 'retrosnap/show/RETROSNAP_SHOW_SET_ERROR'
            }),
            ...mapActions({
                del: 'retrosnap/del/del',
                reset: 'retrosnap/show/reset',
                retrieve: 'retrosnap/show/retrieve',
                gettokendata: 'user/token/gettokendata',
                personRetrieve: 'person/show/retrieve',
                createComment: 'comment/create/create',
                getComments: 'comment/list/default',
                updateImageGallery: 'imagegallery/update/update',
                updateRetrievedImageGallery: 'imagegallery/update/updateRetrieved',
                retrieveImageGallery: 'imagegallery/update/retrieve',
            }),

            setRedirectRoute(route) {
                this.$store.commit('settings/setsettings', Object.assign({}, this.settings, {redirectRoute: route}))
            },
            goToStart(){
                this.showLoginDialog=false;
                this.setRedirectRoute(this.$route.fullPath);
                this.$router.push({path: '/login'})
            },
            deleteItem (item) {
                if (window.confirm('Are you sure you want to delete this item?')) {
                    this.del(item).then(() => this.$router.push({name: 'RetrosnapList'}))
                }
            },
            toggleBackside () {
                this.showBackside = !this.showBackside
            },
            zoomIn () {
                let scrollFactor = this.getScrollFactor()
                let oldZoomFactor = this.zoomFactor
                this.zoomFactor += 50
                if (this.zoomFactor > 500) this.zoomFactor = 500
                let newScrollPos = this.getNewScrollPos(oldZoomFactor, scrollFactor)

                document.querySelector('.zoomImageDialog').scrollTo(newScrollPos.x, newScrollPos.y)
            },
            zoomOut () {
                let scrollFactor = this.getScrollFactor()
                let oldZoomFactor = this.zoomFactor
                this.zoomFactor -= 50
                if (this.zoomFactor < 100) this.zoomFactor = 100
                let newScrollPos = this.getNewScrollPos(oldZoomFactor, scrollFactor)
                document.querySelector('.zoomImageDialog').scrollTo(newScrollPos.x, newScrollPos.y)
            },
            getScrollFactor () {
                let zoomImageDialogWidth = document.querySelector('.zoomImageDialog').clientWidth
                let zoomImageDialogHeight = document.querySelector('.zoomImageDialog').clientHeight
                let zoomImageWidth = document.querySelector('.zoomImage').clientWidth
                let zoomImageHeight = document.querySelector('.zoomImage').clientHeight
                let scrollPosX = document.querySelector('.zoomImageDialog').scrollLeft
                let scrollPosY = document.querySelector('.zoomImageDialog').scrollTop
                let maxScrollX = zoomImageWidth - zoomImageDialogWidth
                let maxScrollY = zoomImageHeight - zoomImageDialogHeight
                return ({'x': parseFloat(scrollPosX / maxScrollX), 'y': parseFloat(scrollPosY / maxScrollY)})
            },
            getNewScrollPos (oldZoomFactor, scrollFactor) {
                let zoomImageDialogWidth = document.querySelector('.zoomImageDialog').clientWidth
                let zoomImageDialogHeight = document.querySelector('.zoomImageDialog').clientHeight
                let zoomImageWidth = document.querySelector('.zoomImage').clientWidth
                let zoomImageHeight = document.querySelector('.zoomImage').clientHeight
                let newZoomImageWidth = (zoomImageWidth / oldZoomFactor) * this.zoomFactor
                let newZoomImageHeight = (zoomImageHeight / oldZoomFactor) * this.zoomFactor
                let newMaxScrollX = newZoomImageWidth - zoomImageDialogWidth
                let newMaxScrollY = newZoomImageHeight - zoomImageDialogHeight
                return {
                    'x': parseFloat(scrollFactor.x * newMaxScrollX),
                    'y': parseFloat(scrollFactor.y * newMaxScrollY)
                }
            },
            isAuthor () {
                if (this.token_data) {
                    return ('/people/' + this.token_data.person_id === this.item.author)
                }
                return false
            },
            init () {
                this.retrieve(decodeURIComponent(this.$route.params.id))
                if (this.token_data && this.token_data.person_id !== null) {
                    this.personRetrieve('/people/' + this.token_data.person_id)
                } else {
                    window.console.log('no tokenData!')
                }

               // this.colorized = this.settings.showColorized
                this.colorized=this.$route.path.indexOf('/color')!==-1;

                this.getComments('/comments?retrosnap=' + this.$route.params.id)
            },
            onSendCommentForm () {
                if (this.newComment.id == null) {
                    this.create(this.newComment)
                } else {
                    this.updateComment(this.newComment)
                }

            },

            updateCommentField (field, value) {
                Object.assign(this.newComment, {[field]: value})
            },
            addComment () {
                if(this.token_data == null){
                    //not logged in:
                    this.showCommentLoginDialog = true;
                    return;
                }
                this.showCommentDialog = true
                this.newComment = {
                    box: null,
                    text: '',
                    isPrivate: false,
                    'retrosnap': this.item['@id'],
                    isBackside: this.showBackside !== false
                }
            },
            commentDialogClose () {
                this.pathEditMode = false
                this.box = null
            },
            doCommentDialogClose () {
                this.showCommentDialog = false
                this.commentDialogClose()
            },
            editComment (commentItem) {
                this.showInfo = false
                this.newComment = JSON.parse(JSON.stringify(commentItem));
                this.showCommentDialog = true
            },
            deleteComment (commentItem) {
                if (window.confirm(this.$t('app.Comment.DeleteConfirm'))) {
                    return fetch(commentItem['@id'], {
                        method: 'DELETE',
                        headers: new Headers({'Content-Type': 'application/ld+json'}),
                        body: JSON.stringify(commentItem)
                    })
                        .then(() => {
                            this.getComments('/comments?retrosnap=' + this.$route.params.id)
                            window.console.log('deleted')
                        })
                        .catch((e) => {
                            this.setError(e)
                            return e

                        })
                }
            },
            reportInappropriateComment (commentItem) {
                window.console.log('reportInappropriateComment', commentItem)
            },
            submitComment () {
                this.showCommentDialog = false
                this.commentDialogClose()
                window.console.log(this.newComment)
                if (this.newComment['@id']) {
                    this.updateComment(this.newComment)
                } else {
                    this.createComment(this.newComment)
                }

            },
            updateComment (comment) {

                return fetch(comment['@id'], {
                    method: 'PUT',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify(comment)
                })
                    .then(response => response.json())
                    .then((data) => {
                        window.console.log(data)
                    })
                    .catch((e) => {
                        this.setError(e)
                        return e

                    })
            },
            commentClick (commentItem) {
                window.console.log(commentItem)
                this.activeComment = commentItem.id
                this.$router.replace({
                    name: 'RetrosnapShowComment',
                    params: {id: this.item['@id'], comment_id: this.activeComment}
                })

            },
            filterCommentItems () {
                this.$nextTick(() => {
                    this.filteredCommentItems = this.commentItems.filter((item) => (item.box && ((item.isBackside === true) === this.showBackside)))
                    window.console.log(this.showBackside, this.filteredCommentItems)
                })
            },
            upvoteComment (commentItem) {
                window.console.log('upvote')
                return fetch(commentItem['@id'] + '/vote/up', {
                    method: 'PUT',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify({})
                })
                    .then(response => response.json())
                    .then((data) => {
                        window.console.log(data)
                        this.getComments('/comments?retrosnap=' + this.$route.params.id)
                    })
                    .catch((e) => {
                        this.setError(e)
                        return e

                    })
            },
            downvoteComment (commentItem) {
                window.console.log('downvote')
                return fetch(commentItem['@id'] + '/vote/down', {
                    method: 'PUT',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify({})
                })
                    .then(response => response.json())
                    .then((data) => {
                        window.console.log(data)
                        this.getComments('/comments?retrosnap=' + this.$route.params.id)
                    })
                    .catch((e) => {
                        this.setError(e)
                        return e

                    })
            },
            getCompleteUrl: function () {
                return window.location.origin + this.$route.fullPath
            },
            htmlAsText (value) {

                return (typeof value == 'undefined' || value == null) ? '' : value.replace(/<\/?[^>]+(>|$)/g, '')
            },
            showPhotosFromCollection(){
                //this.$router.push({name: 'ImageGalleryShow', params: {id: this.item.photograph.collection['@id']}})
                return false;
            },
            closeWin () {
                window.history.length > 2 ? this.$router.go(-1) : this.$router.push('/feed')
            },
            toggleGui(){
                window.console.log('toggleGui');
                this.hideGui=!this.hideGui;
            },

            selectGalery(){
                if (this.selectedGallery===null) return;
                let imagegallery=null;
                this.imagegalleries.forEach((item)=>{
                    if (item['@id']===this.selectedGallery){
                        imagegallery=item;
                        return true;
                    }
                });

                this.addToGallery(imagegallery,this.item);

            },

            addToGallery (imagegallery, item) {
                imagegallery.retrosnaps.push('/retrosnaps/' + item['id'])
                imagegallery.retrosnaps = [...new Set(imagegallery.retrosnaps)]
                //this.updateRetrievedImageGallery({ photographs: imagegallery.photographs })
                this.updateGallery(imagegallery)
            },
            showGallerySelectDialog(slide){
                this.selectedSlide=slide;
                this.gallerySelectDialog=true;
            },

            updateGallery (gallery) {
                let ig = JSON.parse(JSON.stringify(gallery))
                if(typeof ig.retrosnaps!=='undefined') ig.retrosnaps=this.arrayToIris(ig.retrosnaps);
                if(typeof ig.subGalleries!=='undefined') ig.subGalleries=this.arrayToIris(ig.subGalleries);
                if(ig.parentGalleries) delete ig.parentGalleries;
                return fetch(ig['@id'], {
                    method: 'PUT',
                    headers: new Headers({'Content-Type': 'application/ld+json'}),
                    body: JSON.stringify(ig)
                })
                    .then(response => response.json())
                    .then(() => {
                        this.gallerySelectDialog=false;
                        //window.console.log(data)
                    })
                    .catch((e) => {
                        this.$store.error=e.message;
                        return e

                    })
            },
        }
    }
</script>
