<template>
    <v-row
        class="d-flex align-center justify-center "
        :class="!displayColumn ? '' : 'flex-column'"
        no-gutters
    >
        <v-col
            cols="auto"
            class="d-flex justify-center mr-1 mb-2"
        >
            <v-img
                :src="require('@/assets/img/button-HastDuBilder_narrow.jpg')"
                alt="Report Collection"
                height="auto"
                class="clickable-img-mobile"
                @click="handleReportClick"
            ></v-img>
        </v-col>

        <v-col
            cols="auto"
            class="d-flex justify-center align-center mb-2"
        >
            <v-img
                :src="require('@/assets/img/button-crowdfunding_narrow.jpg')"
                alt="Browse Collections"
                height="auto"
                class="clickable-img-mobile"
                cover
                @click="handleBrowseClick"
            ></v-img>
        </v-col>
    </v-row>
</template>
<style>
.clickable-img-mobile {
    cursor: pointer; /* Change cursor to pointer to indicate clickable area */
    transition: transform 0.3s ease; /* Smooth transform for hover effect */
    border-radius: 8px;
    max-width: 200px;
}

.clickable-img-mobile:hover {
    transform: scale(1.05); /* Slightly enlarge the image on hover */

}
@media screen and (max-width: 710px) {
    .clickable-img-mobile{
        max-width: 200px;
    }
}
@media screen and (max-width: 510px) {
    .clickable-img-mobile{
        max-width: 150px;
    }
}
@media screen and (max-width: 410px) {
    .clickable-img-mobile{
        max-width: 150px;
    }
}
@media screen and (max-width: 310px) {
    .clickable-img-mobile{
        max-width: 100px;
    }
}
</style>
<script >
import {ENTRYPOINT} from "@/config/entrypoint";

export default {
    props: ['displayColumn'],
    data(){
        return{
            entrypoint: ENTRYPOINT
        }
    },
    methods: {
        handleBrowseClick() {
            this.$router.push({name: 'CollectionsList'})
        },
        handleReportClick() {
            window.location.href = ' https://nimmerso.app/report_collection';
        },
    },
};
</script>
